import axios, { AxiosRequestConfig } from 'axios';
import { getAPIUrl } from '../config/config';
import { getUser } from './authService';
import { isExternalDomain } from '../utils';

const instance = axios.create({
  baseURL: getAPIUrl(),
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const user = await getUser();

    if (user && config.headers && !isExternalDomain(config.url!)) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
