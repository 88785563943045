import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import TopBar from './TopBar';

export default function UnauthenticatedLayout() {
  return (
    <Box>
      <CssBaseline />
      <TopBar />
      <Box component='main'>
        <Outlet />
      </Box>
    </Box>
  );
}
