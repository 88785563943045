import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { withTranslation, WithTranslation } from 'react-i18next';

interface State {
  hasError: boolean;
}
interface Props extends WithTranslation {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    const { t } = this.props;

    if (hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
          }}
        >
          <Paper elevation={2} sx={{ pt: 2, pl: 3, pr: 3, pb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                component='img'
                sx={{
                  maxWidth: 250,
                  objectFit: 'contain',
                  p: 2,
                  mb: 2,
                }}
                alt='logo Arka'
                src={`${process.env.PUBLIC_URL}/logo.webp`}
              />
              <Stack direction='row' alignItems='center'>
                <ErrorIcon
                  sx={{
                    color: 'error.main',
                    width: '2em',
                    height: '2em',
                    mr: 1,
                  }}
                />
                <Typography variant='h5'>{t('fatal_error_msg')}</Typography>
              </Stack>

              <Button
                onClick={() => window.location.replace(window.location.origin)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {t('go_to_main_page')}
              </Button>
            </Box>
          </Paper>
        </Box>
      );
    }

    return children;
  }
}

export default withTranslation()(ErrorBoundary);
