import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

interface ErrorDialogProps {
  message: string;
  status: boolean;
}

export default function ErrorDialog({ message, status }: ErrorDialogProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(status);
  }, [status]);

  return (
    <Dialog
      open={isOpen}
      className='customDialog'
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      fullWidth
      maxWidth='xs'
      PaperProps={{
        style: { borderRadius: 5 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          backgroundColor: 'error.main',
        }}
      >
        <ErrorIcon
          sx={{ color: 'white', width: '2em', height: '2em', margin: 1 }}
          fontSize='large'
        />
        <IconButton
          size='small'
          sx={{ color: 'white', margin: 0.5 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 16,
        }}
      >
        {message}
      </DialogContent>
    </Dialog>
  );
}
