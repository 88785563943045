import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { isPartner } from '../../utils';
import GlobalContext from './GlobalContext';

const Layout = React.lazy(() => import('./Layout'));

const ReservationsHistory = React.lazy(
  () => import('../reservationsHistory/ReservationsHistory')
);

const ActiveReservations = React.lazy(
  () => import('../activeReservations/ActiveReservations')
);
const ReservationWizard = React.lazy(
  () => import('../reserveDrawers/ReservationWizard')
);
const CheckPayment = React.lazy(() => import('../reserveDrawers/CheckPayment'));
const CheckReserationExtensionPayment = React.lazy(
  () => import('../activeReservations/CheckReservationExtensionPayment')
);
const ExtendReservation = React.lazy(
  () => import('../activeReservations/ExtendReservation')
);

const UserAccount = React.lazy(() => import('../account/UserAccount'));

const Reports = React.lazy(() => import('../reports/Reports'));
const FleetLoadReport = React.lazy(() => import('../reports/FleetLoadReport'));
const LockerLoadReport = React.lazy(
  () => import('../reports/LockerLoadReport')
);
const FleetUptimeReport = React.lazy(
  () => import('../reports/FleetUptimeReport')
);

const DrawersRentalReport = React.lazy(
  () => import('../reports/DrawersRentalReport')
);

export default function PortalRoutes() {
  const loggedUser = React.useContext(GlobalContext);
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Navigate replace to='/reserve-drawer' />} />

        <Route path='reservations-history' element={<ReservationsHistory />} />
        <Route path='active-reservations' element={<ActiveReservations />} />
        <Route
          path='active-reservations/:awb/extend-validity-period'
          element={<ExtendReservation />}
        />

        <Route path='reserve-drawer' element={<ReservationWizard />} />
        <Route path='reservation-status' element={<CheckPayment />} />
        <Route
          path='reservation-extension-status'
          element={<CheckReserationExtensionPayment />}
        />
        <Route path='my-account' element={<UserAccount />} />
        {isPartner(loggedUser) && (
          <>
            <Route path='reports' element={<Reports />} />
            <Route path='reports/fleet-load' element={<FleetLoadReport />} />
            <Route
              path='reports/fleet-load/:lockerCode'
              element={<LockerLoadReport />}
            />
            <Route path='reports/uptime' element={<FleetUptimeReport />} />
            <Route
              path='reports/drawers-rented'
              element={<DrawersRentalReport />}
            />
          </>
        )}
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  );
}
