import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@emotion/react';

import App from './App';
import queryClient from './services/reactQueryClient';
import theme from './theme';
import './config/i18n';
import './assets/css/global.css';
import LoadingLineComponent from './components/common/LoadingLineComponent';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <React.Suspense fallback={<LoadingLineComponent />}>
            <App />
          </React.Suspense>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
