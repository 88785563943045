import {
  PARTNER_ACCESS_TOKEN_KEY,
  PARTNER_COUNTRY_CODE_STOARGE_KEY,
  PARTNER_LOCALE_STOARGE_KEY,
  PARTNER_TIMEZONE_STOARGE_KEY,
} from '../constants';
import { getSessionStorageItem, removeSessionStorageKey } from '../utils';

export const getUser = () => {
  const authUser = getSessionStorageItem(PARTNER_ACCESS_TOKEN_KEY);
  return authUser ? JSON.parse(authUser) : null;
};

export const logout = () => {
  removeSessionStorageKey(PARTNER_ACCESS_TOKEN_KEY);
  removeSessionStorageKey(PARTNER_LOCALE_STOARGE_KEY);
  removeSessionStorageKey(PARTNER_TIMEZONE_STOARGE_KEY);
  removeSessionStorageKey(PARTNER_COUNTRY_CODE_STOARGE_KEY);
  window.location.href = '/';
};
