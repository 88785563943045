import React from 'react';

import LinearProgress from '@mui/material/LinearProgress/LinearProgress';

export default function LoadingLineComponent() {
  return (
    <LinearProgress
      variant='indeterminate'
      color='success'
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }}
    />
  );
}
