import { useQuery } from 'react-query';
import axios from './interceptors';
import { AccountData, CreateAccountData } from '../types/accounts.types';
import {
  ChangePasswordParams,
  DrawerPaymentDTO,
  ExtendReservationParams,
  ForgotPasswordParams,
  KeyValueParams,
  PostLoginParams,
  QueryParams,
  ReserveDrawerDTO,
} from '../types/general.types';
import {
  getCountryName,
  getLocale,
  getQueryString,
  getSessionStorageItem,
  getStartOfTheDayEndOfTheDayUTC,
  getTimezone,
} from '../utils';
import { PARTNER_COUNTRY_CODE_STOARGE_KEY } from '../constants';

export const useDrawerTypes = () => {
  return useQuery(['drawerTypes'], async () => {
    const { data } = await axios.get('/drawerTypes');
    return data;
  });
};

export const useCounties = () => {
  const countryCode = getSessionStorageItem(PARTNER_COUNTRY_CODE_STOARGE_KEY);
  return useQuery(
    'counties',
    async () => {
      const { data } = await axios.get(`/counties/${countryCode}`);
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

export const useLocalitiesByCountyId = (countyId: string, enabled = true) => {
  return useQuery(
    `locality-${countyId}`,
    async () => {
      const { data } = await axios.get(`/counties/${countyId}/localities`);
      return data;
    },
    {
      enabled,
    }
  );
};

export const useLockers = (queryParams: QueryParams, enabled = true) => {
  return useQuery(
    ['lockers', queryParams],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      const queryString = getQueryString(params as QueryParams);
      const { data } = await axios.get(`/lockers?${queryString}`);
      return data;
    },
    { enabled }
  );
};

export const useOwnedLockers = (queryParams: QueryParams) => {
  return useQuery(['owned-lockers', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/lockers/owned?${queryString}`);
    return data;
  });
};

// payment endpoints

export const initDrawerReservationPayment = (paymentData: DrawerPaymentDTO) => {
  const data: DrawerPaymentDTO = {
    mobileView: false,
    returnUrl: 'reservation-status',
  };
  return axios.post('/payment', { ...data, ...paymentData });
};

export const usePaymentStatus = (paymentId: string, enabled = true) => {
  return useQuery(
    ['payment-status', paymentId],
    async () => {
      const { data } = await axios.get(`/payment/${paymentId}`);
      return data;
    },
    { cacheTime: 0, refetchOnWindowFocus: false, enabled }
  );
};

export const checkPaymentStatus = (paymentId: string) => {
  return axios.get(`/payment/${paymentId}`);
};

export const createAccount = (userData: CreateAccountData) => {
  return axios.post('/account', userData);
};

export const deleteAccount = () => {
  return axios.delete(`/account`);
};

export const usePackagesList = (queryParams: QueryParams) => {
  return useQuery(['packages', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/packages?${queryString}`);
    return data;
  });
};

export const cancelReservation = (awb: string) => {
  return axios.delete(`/packages/${awb}`);
};

export const useAccountQuery = () => {
  return useQuery(
    ['getUserData'],
    async () => {
      const { data } = await axios.get('/account');
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const updateUserData = (payload: AccountData) => {
  return axios.put('/account', payload);
};

export const useMapSearchApi = async (queryParams: QueryParams) => {
  const queryString = getQueryString(queryParams);

  const { data } = await axios.get(
    `https://nominatim.openstreetmap.org/search?format=jsonv2&country=${getCountryName()}&${queryString}`
  );
  return data;
};

// used to create a drawer reservation
export const createPackage = (reservationInfo: ReserveDrawerDTO) => {
  return axios.post('/packages?drawerAssignationStrategy=1', reservationInfo);
};

export const useReservationCosts = (queryParams: QueryParams) => {
  return useQuery(['reservation-costs', queryParams], async ({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = getQueryString(params as QueryParams);
    const { data } = await axios.get(`/costs?${queryString}`);
    return data;
  });
};

export const extendReservation = (
  extendReservationInfo: ExtendReservationParams
) => {
  const { awb, ...rest } = extendReservationInfo;
  const queryString = getQueryString({ ...rest } as QueryParams);
  return axios.post(`/packages/${awb}/extend-expiration?${queryString}`);
};

export const useReports = () => {
  return useQuery(
    ['reports'],
    async () => {
      const { data } = await axios.get('http://localhost:8080/reports');
      return data;
    },
    { refetchOnWindowFocus: false }
  );
};

export const downloadReport = (params: {
  queryParams: QueryParams;
  reportType: number;
}) => {
  const { queryParams, reportType } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(`/reports/download-fleed-load/${reportType}?${queryString}`);
};

export const getDrawersLoad = async (params: {
  queryParams: QueryParams;
  lockerCode: string;
}) => {
  const { queryParams, lockerCode } = params;
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(
    `/reports/fleet-load/${lockerCode}/drawers?${queryString}`
  );
  return data;
};

export const getReportInfo = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-global?${queryString}`);
  return data;
};

export const getFleetLoadReport = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-load?${queryString}`);
  return data;
};

export const getFleetUptimeReport = async (queryParams: QueryParams) => {
  const { startDate, endDate } = queryParams;
  const queryString = getQueryString({
    ...queryParams,
    ...getStartOfTheDayEndOfTheDayUTC(startDate as string, endDate as string),
  });
  const { data } = await axios.get(`reports/fleet-uptime?${queryString}`);
  return data;
};

export const downloadDrawersLoadReport = (params: {
  queryParams: QueryParams;
  lockerCode: string;
}) => {
  const { queryParams, lockerCode } = params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pageSize, pageNumber, ...rest } = queryParams;
  const queryString = getQueryString(rest);
  return axios.get(
    `/reports/download-drawer-load/${lockerCode}?${queryString}`
  );
};

export const getAllLockers = () => {
  return axios.get('lockers/owned/lite');
};

export const getLocalitiesByCountyId = (countyId: string) => {
  return axios.get(`/counties/${countyId}/localities`);
};

export const getCustomerBillingReport = async (params: {
  queryParams: QueryParams;
}) => {
  const { queryParams } = params;
  const queryString = getQueryString(queryParams);
  const { data } = await axios.get(`/reports/billing-data?${queryString}`);
  return data;
};

export const downloadBillingData = (params: { queryParams: QueryParams }) => {
  const { queryParams } = params;
  const queryString = getQueryString(queryParams);
  return axios.get(`/reports/download-billing-data?${queryString}`);
};

export const login = (params: PostLoginParams) => {
  return axios.post('account/login', params);
};

export const forgotPassword = (params: ForgotPasswordParams) => {
  return axios.post('account/forgot-password', params);
};

export const changePassword = (params: ChangePasswordParams) => {
  return axios.post('account/change-password', params);
};

export const updateAccountSettings = (params: KeyValueParams) => {
  return axios.put('account/settings', {
    locale: getLocale(),
    timezone: getTimezone(),
    ...params,
  });
};
