import { createTheme } from '@mui/material';
import { roRO } from '@mui/material/locale';
import '@fontsource/roboto/latin.css';
import '@fontsource/roboto/latin-ext.css';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#258EC5',
      },
      secondary: {
        main: '#dedede',
        dark: '#adadad',
      },
    },
  },
  roRO
);

export const backButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: '#dedede',
          color: 'black',
          '&:hover': {
            background: '#adadad',
          },
          '&:active': {
            background: '#969696',
          },
        },
      },
    },
  },
});

export default theme;
