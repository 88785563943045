import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  handleAcceptGDPR: () => void;
  handleRejectGDPR: () => void;
}

export default function GdprPage({
  handleAcceptGDPR,
  handleRejectGDPR,
}: Props) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        width: '100%',
        background: '#323232',
        position: 'fixed',
        bottom: 0,
        zIndex: 10000,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant='outlined'
          sx={{ color: 'lightgray', border: '1px solid lightgray' }}
          onClick={handleRejectGDPR}
        >
          {t('continue_without_consent')}
        </Button>
      </Box>
      <Typography
        variant='h5'
        sx={{
          color: 'white',
          mb: 2,
        }}
      >
        {t('we_care_about_your_privacy')}
      </Typography>
      <Typography
        sx={{
          color: 'white',
          textAlign: 'justify',
          mb: 2,
        }}
      >
        {`${t('cookie_policy')}`}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
        <Button variant='contained' onClick={handleAcceptGDPR}>
          {t('accept')}
        </Button>
      </Box>
    </Box>
  );
}
