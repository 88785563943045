import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { ReactElement } from 'react';
import { AxiosError } from 'axios';
import { DateTime, Settings } from 'luxon';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import Fuse from 'fuse.js';
import L from 'leaflet';
import {
  ABOVE_AVERAGE_FILL_COLOR,
  AVERAGE_FILL_COLOR,
  BELLOW_AVERAGE_FILL_COLOR,
  COUNTRIES_CENTER_POINT_COORDINATES,
  COUNTRIES_COORDINATES_BOUNDS,
  COUNTRIES_MAPPING,
  DEFAULT_LOCALE,
  DEFAULT_TIMEZONE,
  errorMessages,
  EXTERNAL_DOMAINS,
  MIN_WIDTH_ACTIONS,
  PARTNER_COUNTRY_CODE_STOARGE_KEY,
  PARTNER_LOCALE_STOARGE_KEY,
  PARTNER_ROUTES,
  PARTNER_TIMEZONE_STOARGE_KEY,
  RO_COUNTY_CODE,
  ROUTES,
  UNAUTH_PARTNER_LOCALE_STOARGE_KEY,
  USER_TYPES,
} from '../constants';
import {
  Address,
  InternalPaymentStatus,
  Locker,
  MinWidths,
  MIN_WIDTH_DATAGRID,
  PaymentDTO,
  PaymentType,
  QueryParams,
  County,
  Locality,
  LOCKER_TYPE,
  CustomJwtPayload,
  LoggedInUser,
  DrawerType,
} from '../types/general.types';
import i18n from '../config/i18n';
import { getUser } from '../services/authService';

export const getActionsColumn = (
  renderCell: (params: GridRenderCellParams) => ReactElement
) => {
  return {
    field: 'actions',
    headerName: i18n.t('actions'),
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    minWidth: MIN_WIDTH_ACTIONS,
    renderCell,
  };
};

export const generateGridColumnsHeader = (
  availableHeaders: string[]
): GridColDef[] => {
  return availableHeaders.map((header) => {
    return {
      field: header,
      headerName: header,
      flex: 1,
    };
  });
};

export const generateGridColumnsHeaderRomanian = (
  availableHeaders: string[],
  availableDbNames: string[],
  minWidth: number,
  sortModel?: { field: string; sort: string },
  minWidths?: MinWidths
): GridColDef[] => {
  return availableHeaders.map((header, idx) => {
    const headerName = i18n.t(header);
    return {
      field: availableDbNames[idx],
      headerName,
      flex: 1,
      disableColumnMenu: true,
      headerClassName:
        sortModel && sortModel.field === availableDbNames[idx]
          ? `data-grid-custom-${sortModel.sort}`
          : '',
      minWidth:
        minWidths && minWidths[availableDbNames[idx]]
          ? minWidths[availableDbNames[idx]]
          : minWidth,
    };
  });
};

// Generates the query string used in endpoints
// to add search, sort, pagination.
export const getQueryString = (queryParams: QueryParams) => {
  const cleanQueryParams = Object.fromEntries(
    Object.entries(queryParams)
      .filter(([, val]) => val !== '')
      .map(([_, val]) => {
        return [_, val.toString()];
      })
  );
  return new URLSearchParams(cleanQueryParams).toString();
};

export const getErrorMessage = (error: AxiosError, defaultErrorMsg: string) => {
  const { response } = error;
  if (response && Array.isArray(response.data)) {
    return errorMessages[response.data[0]]
      ? i18n.t(errorMessages[response.data[0]])
      : defaultErrorMsg;
  }
  return defaultErrorMsg;
};

export const getLockerAddress = (locker: Locker): string => {
  const { address } = locker;
  let baseAddress = `${i18n.t('locality_lower')} ${
    locker.address?.locality?.name
  }, ${i18n.t('street_lower')} ${locker.address?.street}`;
  if (address?.buildingNumber) {
    baseAddress += `, ${i18n.t('number_lower')} ${address.buildingNumber}`;
  }
  if (address?.floor) {
    baseAddress += `, ${i18n.t('floor_lower')} ${address.floor}`;
  }
  return baseAddress;
};

export const isPartner = (user: LoggedInUser | null) => {
  if (user?.profile.user_type === USER_TYPES.PARTNER) {
    return true;
  }
  return false;
};

export const getReservationWizardSteps = (user: LoggedInUser) => {
  const steps = ['choose_drawer_size', 'choose_drawer_location'];
  if (isPartner(user)) {
    steps.push('confirm_upper');
  } else {
    steps.push('pay');
  }
  return steps;
};

export const getCustomColumn = (
  fieldName: string,
  headerName: string,
  minWidth: number
): GridColDef => {
  return {
    field: fieldName,
    headerName: i18n.t(headerName) as string,
    disableColumnMenu: true,
    flex: 1,
    minWidth,
  };
};

export const getCountyColumn = () => {
  const column = getCustomColumn(
    'judet',
    'county',
    MIN_WIDTH_DATAGRID.OWNED_LOCKERS
  );
  return {
    ...column,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row?.address?.county?.name;
    },
  };
};

export const getCompleteAddress = (address: Address): string => {
  let completeAdress = '';
  if (address) {
    const { county, locality, buildingNumber, floor, street, postCode } =
      address;
    if (county && county.name) {
      completeAdress += `${i18n.t('county_lower')} ${county.name}`;
    }
    if (locality && locality.name) {
      completeAdress += `, ${i18n.t('locality_lower')} ${locality.name}`;
    }
    if (street) {
      completeAdress += `, ${i18n.t('street_lower')} ${street}`;
    }
    if (buildingNumber) {
      completeAdress += `, ${i18n.t('number_lower')} ${buildingNumber}`;
    }
    if (floor) {
      completeAdress += `, ${i18n.t('floor_lower')} ${floor}`;
    }
    if (postCode) {
      completeAdress += `, ${i18n.t('postal_code_lower')} ${postCode}`;
    }
  }
  return completeAdress;
};

// checks for additional ranges of latin characters
export const isAlphaName = (string: string) => {
  return /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024FF ,'-]+$/.test(string);
};
// checks for additional ranges of latin characters
export const isAlphaNumericName = (string: string) => {
  return /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024FF0-9 ,'-]+$/.test(
    string
  );
};

export const getPaymentId = (
  payments: PaymentDTO[],
  paymentType: PaymentType
) => {
  const foundPayment = payments.find(
    (payment) =>
      payment.type === paymentType &&
      payment.status !== InternalPaymentStatus.paid
  );

  return foundPayment ? foundPayment.paymentId : '';
};

export const getPaymentStatus = (
  payments: PaymentDTO[],
  paymentType: PaymentType
) => {
  const foundPayment = payments.find((payment) => payment.type === paymentType);

  return foundPayment ? foundPayment.status : '';
};

export const getPaymentListByType = (
  payments: PaymentDTO[],
  paymentType: PaymentType
) => {
  return payments.filter((payment) => payment.type === paymentType);
};

export const hasUnpaidPayment = (
  payments: PaymentDTO[],
  paymentType: PaymentType
) => {
  const unpaidPayment = payments.filter(
    (payment) =>
      payment.type === paymentType &&
      payment.status !== InternalPaymentStatus.paid
  );
  return unpaidPayment.length > 0;
};

export const getUnpaidPaymentId = (payments: PaymentDTO[]) => {
  const unpaidPayment = payments.find(
    (payment) => payment.status !== InternalPaymentStatus.paid
  );
  return unpaidPayment?.paymentId;
};

export const getReservationCost = (payments: PaymentDTO[]) => {
  const reservationPayment = payments.find(
    (payment) => payment.type === PaymentType.reservation
  );
  return reservationPayment?.amount || '';
};

export const getAddress = (address: Address): string => {
  let completeAdress = '';
  if (address) {
    const { buildingNumber, floor, street, postCode } = address;
    if (street) {
      completeAdress += `${i18n.t('street_short')} ${street}`;
    }
    if (buildingNumber) {
      completeAdress += `, ${i18n.t('number_short')} ${buildingNumber}`;
    }
    if (floor) {
      completeAdress += `, ${i18n.t('floor')} ${floor}`;
    }
    if (postCode) {
      completeAdress += `, ${i18n.t('postal_code')} ${postCode}`;
    }
  }
  return completeAdress;
};

export const getYesterdayDate = () => {
  const now = new Date();
  now.setDate(now.getDate() - 1);
  return now;
};

export const getStartOfMonthyDate = () => {
  const now = new Date();
  now.setDate(1);
  return now;
};

export const getDateFormat = (date: Date, serverFormat = false) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return serverFormat
    ? `${[year, month, day].join('-')}`
    : `${[day, month, year].join('/')}`;
};

export const validateDates = (startDate: Date | null, endDate: Date | null) => {
  let isDateValid: boolean = false;
  if (startDate !== null && endDate !== null) {
    if (startDate.getTime() <= endDate.getTime()) {
      isDateValid = true;
    }
  }
  return isDateValid;
};

export const downloadFile = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'application/octet-stream' })
  );
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.setAttribute('id', 'temporaryAnchor');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.getElementById('temporaryAnchor')?.remove();
};

export const getRoutes = (user: LoggedInUser | null) => {
  return isPartner(user) ? PARTNER_ROUTES : ROUTES;
};

export const getStartDateUTC = (date: Date) => {
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString();
};

export const getEndDateUTC = (date: Date) => {
  date.setUTCHours(23, 59, 59, 999);
  return date.toISOString();
};

export const getAddressColumn = () => {
  const column = getCustomColumn(
    'address',
    'address',
    MIN_WIDTH_DATAGRID.COMPLETE_ADDRESS
  );
  return {
    ...column,
    valueGetter: (params: GridValueGetterParams) => {
      return getAddress(params.row?.address);
    },
  };
};

export const getDonutChartFillColor = (value: number) => {
  if (value >= 90) {
    return ABOVE_AVERAGE_FILL_COLOR;
  }
  if (value > 50) {
    return AVERAGE_FILL_COLOR;
  }

  return BELLOW_AVERAGE_FILL_COLOR;
};

// fuzzy search for arrays of objects
export const createFuseSearch = <T>(searchList: T[], keys: string[]) => {
  const options = {
    keys,
  };
  return new Fuse(searchList, options);
};

export const filterMapLockerList = (lockers: Locker[]) => {
  const lockersNotInClusters: Locker[] = [];
  const lockersInClusters: Locker[] = [];
  lockers.forEach((el) => {
    if (Object.is(el.clusterId, null)) {
      lockersNotInClusters.push(el);
    } else {
      lockersInClusters.push(el);
    }
  });
  const lockersInClustersToShow = lockersInClusters.filter(
    (el, index, sourceArray) =>
      index === sourceArray.findIndex((item) => item.clusterId === el.clusterId)
  );

  return [...lockersNotInClusters, ...lockersInClustersToShow];
};

export const getCityName = (county: County, locality: Locality) => {
  let cityName = '';
  if (locality) {
    cityName = locality.name;
  }
  return cityName;
};

export const getCountyName = (county: County, locality: Locality) => {
  let countyName = '';
  if (county) {
    countyName = county.name;
  }
  if (locality?.aditionalInformation) {
    countyName = '';
  }
  return countyName;
};

export const getMarkerIcon = (isClusterLocker: boolean, lockerType: number) => {
  let iconUrl = 'assets/images/connected-locker.svg';

  if (lockerType === LOCKER_TYPE.autonomous_locker) {
    iconUrl = 'assets/images/autonomous-locker.svg';
  }
  if (isClusterLocker) {
    iconUrl = 'assets/images/cluster.svg';
  }
  return new L.Icon({
    iconUrl,
    iconSize: [42, 64],
    iconAnchor: [22, 63],
  });
};

export const setSessionStorageKey = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const removeSessionStorageKey = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorageItem = (key: string) => {
  return sessionStorage.getItem(key);
};

export const decodeJWTToken = (token: string) => {
  return jwt_decode<CustomJwtPayload>(token);
};

export const getAuthUserData = (token: string) => {
  const decodedToken = decodeJWTToken(token);
  return {
    access_token: token,
    expires_at: decodedToken.exp,
    token_type: 'Bearer',
    profile: {
      user_type: decodedToken.client_user_type || '',
      username: decodedToken.client_preferred_username || '',
    },
  };
};

// password validation rule: min 8 characters, at least one lower case letter,
// at least one upper case letter, at least one number and
// at least one non alpha numeric character
export const validatePassword = (password: string) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-._!"`'#%&,:;<>=@{}~$()*/\\?[\]^|])[A-Za-z\d-._!"`'#%&,:;<>=@{}~$()*/\\?[\]^|]{8,}$/;
  return re.test(password);
};

export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const setDateTimeDefaults = () => {
  Settings.defaultLocale =
    (getSessionStorageItem(PARTNER_LOCALE_STOARGE_KEY) as string) ||
    DEFAULT_LOCALE;
  Settings.defaultZone =
    (getSessionStorageItem(PARTNER_TIMEZONE_STOARGE_KEY) as string) ||
    DEFAULT_TIMEZONE;
};

export const getDateTimeFormat = (date: string) => {
  setDateTimeDefaults();
  const dt = DateTime.fromISO(date);
  return dt.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
};

export const isoStringToDate = (date: string) => {
  setDateTimeDefaults();
  const dt = DateTime.fromISO(date);
  return dt.toLocaleString(DateTime.DATE_SHORT);
};

export const getFullYear = (date: Date) => {
  setDateTimeDefaults();
  return DateTime.fromJSDate(date).toFormat('y');
};

export const getPaddedMonth = (date: Date) => {
  setDateTimeDefaults();
  return DateTime.fromJSDate(date).toFormat('LL');
};

export const getStartOfYearFromISOString = (isoString: string) => {
  setDateTimeDefaults();
  const date = DateTime.fromISO(isoString).toJSDate();
  const startOfYear = DateTime.fromJSDate(date).startOf('year').toJSDate();
  return startOfYear;
};

export const convertDateStringToDateShort = (dateString: string) => {
  const parsedDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd');

  return parsedDate.toLocaleString(DateTime.DATE_SHORT);
};

export const getCSVReportName = (
  baseName: string,
  startDate: string,
  endDate: string
) => {
  return `${baseName}-${convertDateStringToDateShort(
    startDate
  )}-${convertDateStringToDateShort(endDate)}.csv`;
};

export const getLocale = () => {
  return getSessionStorageItem(PARTNER_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE;
};

export const getTimezone = () => {
  return (
    getSessionStorageItem(PARTNER_TIMEZONE_STOARGE_KEY) || DEFAULT_TIMEZONE
  );
};

export const isExternalDomain = (urlString: string) => {
  try {
    const url = new URL(urlString);
    const domain = url.hostname;
    return EXTERNAL_DOMAINS.includes(domain);
  } catch (error) {
    return false;
  }
};

export const getCountryCentralPointCoordinates = () => {
  const countryCode = getSessionStorageItem(
    PARTNER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_CENTER_POINT_COORDINATES[countryCode!];
};

export const getCountryCoordinatesBounds = () => {
  const countryCode = getSessionStorageItem(
    PARTNER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_COORDINATES_BOUNDS[countryCode!];
};

export const getCountryName = () => {
  const countryCode = getSessionStorageItem(
    PARTNER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  return COUNTRIES_MAPPING[countryCode!];
};

export const getLocalityLabel = (locality: Locality) => {
  const countryCode = getSessionStorageItem(
    PARTNER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();
  if (countryCode === RO_COUNTY_CODE) {
    return locality.name;
  }
  return locality.adM2Name
    ? `${locality.name}, ${locality.adM2Name}`
    : locality.name;
};

export const getSearchParams = (
  county: County,
  locality: Locality
): QueryParams => {
  const countryCode = getSessionStorageItem(
    PARTNER_COUNTRY_CODE_STOARGE_KEY
  )?.toLocaleLowerCase();

  if (countryCode === RO_COUNTY_CODE) {
    return {
      county: getCountyName(county, locality),
      city: locality?.aditionalInformation ? '' : getCityName(county, locality),
      postalcode: locality?.aditionalInformation
        ? locality.aditionalInformation
        : '',
    };
  }
  let state = '';
  let selectedCounty = '';
  let city = '';
  if (county) {
    state = county.name;
  }
  if (locality) {
    city = locality.name;
    if (locality.adM2Name) {
      selectedCounty = locality.adM2Name.replace(/^Okres\s+/i, '');
    }
  }
  return {
    state,
    county: selectedCounty,
    city,
  };
};

export const setLocalStorageKey = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export const getPortalLocale = () => {
  if (getUser()) {
    return getSessionStorageItem(PARTNER_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE;
  }
  return (
    getLocalStorageItem(UNAUTH_PARTNER_LOCALE_STOARGE_KEY) || DEFAULT_LOCALE
  );
};

// dateString should be in the 2023-10-13(yyyy-MM-dd) format
export const convertToUTCAtMidnight = (dateString: string) => {
  const inputDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd', {
    zone: 'utc',
  });

  const utcDate = inputDate
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .setZone('UTC');

  return utcDate.toISO();
};
// dateString should be in the 2023-10-13(yyyy-MM-dd) format
export const convertToUTCEndOfDay = (dateString: string) => {
  const inputDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd', {
    zone: 'utc',
  });

  const utcDate = inputDate
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .setZone('UTC');

  return utcDate.toISO();
};

// the startDate and endDate should be in the 2023-10-13(yyyy-MM-dd) format
export const getStartOfTheDayEndOfTheDayUTC = (
  startDate: string,
  endDate: string
) => {
  return {
    startDate: convertToUTCAtMidnight(startDate),
    endDate: convertToUTCEndOfDay(endDate),
  };
};

export const jsDateToYYYYMMDDFormat = (date: Date) => {
  return date.toISOString().slice(0, 10);
};

export const getDrawerDimensions = (drawer: DrawerType) => {
  const { width, height, length, label } = drawer;
  return `${label} (${i18n.t('width_short')} ${width}cm x ${i18n.t(
    'height_short'
  )} ${height}cm x ${i18n.t('length_short')} ${length}cm)`;
};

export const getDrawerTypeBySize = (
  drawerTypeList: DrawerType[],
  size: number
) => {
  return drawerTypeList.find((drawerType) => drawerType.size === size);
};

export const sortDrawerTypesBySize = (drawerTypeList: DrawerType[]) => {
  return drawerTypeList.sort((a: DrawerType, b: DrawerType) => a.size - b.size);
};

export const sortDrawerTypesByVolume = (drawerTypeList: DrawerType[]) => {
  const drawersWithVolume = drawerTypeList.map((drawerType) => ({
    ...drawerType,
    volume: drawerType.width * drawerType.length * drawerType.height,
  }));

  const sortedDrawers = drawersWithVolume.sort((a, b) => a.volume - b.volume);

  return sortedDrawers;
};

export const formatNumberToDecimalOrInteger = (num: number) => {
  if (Number.isInteger(num)) {
    return num;
  }
  return num.toFixed(2);
};

// 90 days ago starting yesterday
export const getDailyReportsMinDate = () => {
  setDateTimeDefaults();
  const yesterday = DateTime.utc().minus({ days: 1 });
  const ninetyDaysAgo = yesterday.minus({ days: 90 });
  return ninetyDaysAgo.toJSDate();
};
