import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export interface LoadingComponentProps {
  isOpen?: boolean;
}

export default function LoadingComponent({
  isOpen = true,
}: LoadingComponentProps) {
  return (
    <Backdrop
      className='spinner-container'
      open={isOpen}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: '#ffffff',
          animationDuration: '800ms',
          position: 'relative',
        }}
        size={60}
        thickness={4}
      />
    </Backdrop>
  );
}
