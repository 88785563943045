import { JwtPayload } from 'jwt-decode';

export interface QueryParams {
  [key: string]: string | number | boolean;
}

export interface RoutesType {
  [key: string]: string;
}

export interface MinWidths {
  [key: string]: number;
}

export interface IPagination {
  totalCount: number;
}

export interface PaginationInfo {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export enum MIN_WIDTH_DATAGRID {
  DRAWERS_RESERVATIONS_HISTORY = 190,
  OWNED_LOCKERS = 200,
  COMPLETE_ADDRESS = 600,
  FLEET_LOAD = 170,
  LOCKER_LOAD = 100,
}

export const DRAWERS_RESERVATIONS_COLUMN_WIDTHS = {
  awb: 190,
  workflow: 190,
  packageState: 180,
  dropoffPin: 190,
  pickupPin: 190,
  paymentState: 190,
  expirationDate: 190,
  updatedAt: 190,
  dropoffDate: 190,
  pickupDate: 190,
  createdAt: 190,
  address: 700,
  drawerPosition: 140,
  lockerCode: 150,
};

export type DateType = Date | null | undefined;

export enum Direction {
  RIGHT = 'right',
  LEFT = 'left',
}

export enum DRAWER_SIZE {
  'small_upper' = 1,
  'medium_upper',
  'laerge_upper',
}
export enum DRAWER_SIZE_CLASSES {
  'small' = 1,
  'medium',
  'large',
}

export interface County {
  countyId: string;
  name: string;
}
export interface Locality {
  localityId: string;
  name: string;
  aditionalInformation?: string;
  adM2Name?: string;
}

export interface Address {
  addressId?: string;
  county?: County;
  locality?: Locality;
  street?: string;
  buildingNumber?: string;
  floor?: string;
  postCode?: string;
  description?: string;
  countyId?: string;
  localityId?: string;
}
export interface AppVersion {
  version?: string;
  appVersionId?: string;
}

export interface Cluster {
  clusterId: string;
  name: string;
}

export interface Locker {
  name: string;
  lockerCode: string;
  lockerState: number | string;
  description: string;
  mac?: string | null;
  appVersion?: AppVersion;
  targetVersion?: AppVersion;
  latitude: number | string;
  longitude: number | string;
  address?: Address;
  targetVersionId?: string;
  lockerType?: number | string;
  schedule?: string;
  cluster: Cluster | null;
  clusterId?: string;
}

export enum RESERVATION_WIZARD_STEPS {
  'SELECT_DRAWER_TYPE' = 0,
  'SELECT_LOCKER',
  'PAY',
}

export interface DrawerType {
  drawerTypeId: string;
  size: number;
  height: number;
  length: number;
  width: number;
  label: string;
}

export interface ReserveDrawerDTO {
  width: number;
  height: number;
  length: number;
  lockerCode?: string;
  clusterId?: string;
}
export interface DrawerPaymentDTO {
  paymentId?: string;
  mobileView?: boolean;
  returnUrl?: string;
}

export enum LockerState {
  active = 1,
  inactive,
}

export interface ExtendReservationParams {
  awb: string;
  hours: string;
}

export enum StatusPayment {
  successful = 1,
  pending,
  failed,
}
export enum PaymentType {
  reservation = 1,
  extension,
}
export enum InternalPaymentStatus {
  none = 1,
  pending = 2,
  failed = 3,
  failedInternal = 4,
  paid = 5,
}

export interface PaymentDTO {
  amount: number;
  paymentId: string;
  paymentProvider: number;
  retryCount: number;
  status: InternalPaymentStatus;
  type: PaymentType;
  updatedAt: string;
  createdAt: string;
}

export enum LOCKER_TYPE {
  'connected_locker' = 1,
  'autonomous_locker',
}

export enum DrawerTypeSize {
  small = 1,
  medium,
  large,
}

export enum ReportType {
  FLEET_LOAD = 1,
  FLEET_UPTIME = 2,
  DRAWERS_RENTAL = 3,
}

export enum DownloadReportType {
  FLEET_LOAD = 1,
  FLEET_UPTIME = 2,
  CLIENT_BILLING_DATA = 4,
}

export interface CustomerBillingData {
  drawerSize: number;
  ownedDays: number;
  ownedDrawers: number;
  sharedDays: number;
  sharedDrawers: number;
}

export interface PostLoginParams {
  username: string;
  password: string;
}

export interface SuccessLoginData {
  accessToken: string;
  userSettings: {
    locale: string;
    timezone: string;
    countryCode: string;
  };
}

export interface ForgotPasswordParams {
  email: string;
  setPasswordRoute: string;
  cultureName: string;
}

export interface CustomJwtPayload extends JwtPayload {
  client_user_type: string;
  client_preferred_username: string;
}

export interface ChangePasswordParams {
  userId: string;
  password: string;
  token: string;
}

export interface LoggedInUser {
  profile: {
    user_type: string;
    username: string;
  };
}

export interface TimeZone {
  key: string;
  value: string;
}

export type KeyValueParams = {
  [key: string]: any;
};
