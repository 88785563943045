import React, { useEffect, useState } from 'react';
import { Settings } from 'luxon';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from './components/common/ErrorBoundary';
import GlobalContext from './components/common/GlobalContext';
import LoadingLineComponent from './components/common/LoadingLineComponent';
import PortalRoutes from './components/common/PortalRoutes';
import UnauthenticatedRoutes from './components/common/UnauthenticatedRoutes';
import GdprPage from './components/GDPR page/GdprPage';
import { getUser, logout } from './services/authService';
import {
  delay,
  getAuthUserData,
  getSessionStorageItem,
  setLocalStorageKey,
  setSessionStorageKey,
} from './utils';
import {
  DEFAULT_LOCALE,
  DEFAULT_TIMEZONE,
  PARTNER_ACCESS_TOKEN_KEY,
  PARTNER_COUNTRY_CODE_STOARGE_KEY,
  PARTNER_LOCALE_STOARGE_KEY,
  PARTNER_TIMEZONE_STOARGE_KEY,
  UNAUTH_PARTNER_LOCALE_STOARGE_KEY,
} from './constants';
import { LoggedInUser, SuccessLoginData } from './types/general.types';
import LoadingComponent from './components/common/LoadingComponent';

function App() {
  const [user, setUser] = useState<LoggedInUser | null>(getUser());
  const [openGDPR, setOpenGDPR] = useState(!localStorage.getItem('GDPR'));
  const [isLoading, setIsLoading] = useState(false);

  const { i18n } = useTranslation();

  const setLoggedUserData = async () => {
    const loggedUser = getUser();
    const locale = getSessionStorageItem(PARTNER_LOCALE_STOARGE_KEY);
    const timezone = getSessionStorageItem(PARTNER_TIMEZONE_STOARGE_KEY);
    Settings.defaultLocale = locale || DEFAULT_LOCALE;
    Settings.defaultZone = timezone || DEFAULT_TIMEZONE;

    if (locale) {
      i18n.changeLanguage(locale);
    }
    if (loggedUser) {
      setUser(loggedUser);
      const timeout = loggedUser.expires_at * 1000 - Date.now();
      if (timeout > 0) {
        await delay(timeout);
        setIsLoading(true);
        await delay(1000);
        logout();
      }
    }
  };

  useEffect(() => {
    setLoggedUserData();
  }, []);

  const setLoginData = (data: SuccessLoginData) => {
    const { accessToken, userSettings } = data;
    const { locale, timezone, countryCode } = userSettings;

    if (locale) {
      setSessionStorageKey(PARTNER_LOCALE_STOARGE_KEY, locale);
      setLocalStorageKey(UNAUTH_PARTNER_LOCALE_STOARGE_KEY, locale);
    } else {
      setSessionStorageKey(PARTNER_LOCALE_STOARGE_KEY, DEFAULT_LOCALE);
    }
    if (timezone) {
      setSessionStorageKey(PARTNER_TIMEZONE_STOARGE_KEY, timezone);
    } else {
      setSessionStorageKey(PARTNER_TIMEZONE_STOARGE_KEY, DEFAULT_TIMEZONE);
    }
    if (countryCode) {
      setSessionStorageKey(PARTNER_COUNTRY_CODE_STOARGE_KEY, countryCode);
    }

    setSessionStorageKey(
      PARTNER_ACCESS_TOKEN_KEY,
      JSON.stringify(getAuthUserData(accessToken))
    );
    setLoggedUserData();
  };

  const handleAcceptGDPR = () => {
    localStorage.setItem('GDPR', 'accepted');
    setOpenGDPR(false);
  };

  const handleRejectGDPR = () => {
    setOpenGDPR(false);
  };

  return (
    <div className='App'>
      <ErrorBoundary>
        {isLoading && <LoadingComponent />}
        <React.Suspense fallback={<LoadingLineComponent />}>
          {user ? (
            <GlobalContext.Provider value={user}>
              <PortalRoutes />
            </GlobalContext.Provider>
          ) : (
            <UnauthenticatedRoutes setLoginData={setLoginData} />
          )}
        </React.Suspense>
      </ErrorBoundary>
      {openGDPR && (
        <GdprPage
          handleAcceptGDPR={handleAcceptGDPR}
          handleRejectGDPR={handleRejectGDPR}
        />
      )}
    </div>
  );
}

export default App;
